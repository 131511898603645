.teclado-termo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.key-row {
  display: flex;
  justify-content: center;
  margin: 5px 0;
}

.key {
  width: 50px;
  height: 50px;
  margin-left: 15px;
  border: none;
  border-radius: 10px; /* Suaviza os cantos para combinar com o neomorfismo */
  background-color: #1C1C1C; /* Mesma cor de fundo para dar o efeito de "afundamento" */
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  color: #ffffff; /* Mantém a cor do texto branca */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 
    inset 5px 5px 10px rgba(0, 0, 0, 0.7),   /* Sombra interna escura (inferior) */
    inset -5px -5px 10px rgba(255, 255, 255, 0.1),  /* Sombra interna clara (superior) */
    5px 5px 10px rgba(0, 0, 0, 0.5),         /* Sombra externa escura */
    -5px -5px 10px rgba(255, 255, 255, 0.1); /* Sombra externa clara */
}

.key:hover {
  background-color: #1C1C1C; /* Mantém a cor de fundo ao passar o mouse */
  box-shadow: 
    inset 5px 5px 15px rgba(0, 0, 0, 0.7),   /* Sombra interna mais intensa no hover */
    inset -5px -5px 15px rgba(255, 255, 255, 0.15),
    7px 7px 15px rgba(0, 0, 0, 0.5),
    -7px -7px 15px rgba(255, 255, 255, 0.1);
}


.large-key {
  width: 100px;
}

.key-label {
  z-index: 1;
  position: relative;
}

.key-parts {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.key-part {
  flex-grow: 1;
  height: 100%;
}

.key-part.correct {
  background-color: #6aaa64;
}

.key-part.present {
  background-color: #c9b458;
}

.key-part.absent {
  background-color: #2a2a2a;
}
