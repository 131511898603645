.titulo {
    font-family: Arial, Helvetica, sans-serif;
    color: #ffaa00;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 2px;
  }
  
  #dot {
    width: 55px;
    position: absolute;
    filter: drop-shadow(1px 1px 9px #ffffff);
    transition: top 0.8s, left 0.8s, background-color 0.3s;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
  
  #wrapper {
    background-color: transparent;
    height: 25px;
    width: 25px;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: top 0.8s, left 0.8s;
    z-index: 2;
  }
  
  #arrow {
    color: white;
    position: absolute;
    top: calc(50% - 0.5em);
    left: 60%;
    font-size: 5rem;
    animation: move-arrow 1s ease 0s infinite alternate none;
    transition: opacity 0.5s;
  }
  
  @keyframes move-arrow {
    from {
      transform: translateX(-50%);
    }
    to {
      transform: translateX(50%);
    }
  }
  
  
  hr {
    width: 320px;
  }
  