.quiz-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background: #1c1c1c;
    border-radius: 15px;
    box-shadow: 8px 8px 15px #0e0e0e, -8px -8px 15px #2a2a2a;
    color: #d1d1d1;
    text-align: center;
}

h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.options {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.option-button {
    padding: 10px;
    font-size: 18px;
    background: #1c1c1c;
    border-radius: 10px;
    border: 2px solid #444;
    color: #d1d1d1;
    cursor: pointer;
    transition: background 0.2s ease, box-shadow 0.2s ease;
}

.option-button:hover {
    background: #2a2a2a;
}

/* Estilo para a alternativa selecionada */
.option-button.selected {
    border-color: var(--accent-color); /* Borda verde */
    background: #2e2e2e; /* Fundo mais claro para destaque */
}

.option-button.correct {
    border-color: transparent !important;
    background: #6aaa64;
    color: white;
}

.option-button.incorrect {
    border-color: transparent !important;
    background: #d9534f;
    color: white;
}

.option-button:disabled {
    cursor: not-allowed;
}

.submit-button, .next-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    background: #1c1c1c;
    border-radius: 10px;
    border: 2px solid #444;
    color: #d1d1d1;
    cursor: pointer;
    transition: background 0.2s ease, box-shadow 0.2s ease;
}

.submit-button:hover, .next-button:hover {
    background: #2a2a2a;
}

.submit-button:disabled {
    cursor: not-allowed;
}

/* MODAL */

/* Estilos do modal */
.quiz-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  
  .quiz-modal-content {
    background: rgb(50,50,50);
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    width: 80%;
    max-width: 400px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    animation: fadeInModal 0.5s ease;
  }
  
  .quiz-modal h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .quiz-modal p {
    margin-bottom: 20px;
    font-size: 18px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .modal-button {
    width: 47%;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .modal-button:hover {
    background-color: #0056b3;
  }
  
  @keyframes fadeInModal {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
