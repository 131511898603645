:root {
    --background-color: #1C1C1C; /* Charcoal */
    --primary-color: #3A3D98; /* Midnight Blue */
    --secondary-color: #5C5C6D; /* Slate Gray */
    --accent-color: #FF4500; /* Fire Opal */
    --text-color: #E0E0E0; /* Light Gray */
    --card-background-color: #2B2B2B; /* Darker Gray for cards */
    --card-hover-shadow: rgba(0, 0, 0, 0.3); /* Stronger shadow for hover */
    --card-text-color: #F0F0F0; /* Even lighter gray for text in cards */
  }
  
  .noticias-page {
    padding: 20px;
    background-color: var(--background-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .news-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
  }
  
  .news-card {
    background-color: var(--card-background-color);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .news-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px var(--card-hover-shadow);
    cursor: pointer;
  }
  
  .news-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  
  .news-content {
    padding: 20px;
  }
  
  .news-category {
    color: var(--accent-color);
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
  }
  
  .news-title {
    font-size: 1.5rem;
    color: var(--text-color);
    margin: 10px 0;
  }
  
  .news-description {
    font-size: 1rem;
    color: var(--card-text-color);
    margin-bottom: 15px;
  }
  
  .news-date {
    font-size: 0.8rem;
    color: var(--secondary-color);
  }
  