/* Global layout */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.header, .title {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.section {
  width: 100%;
  margin-bottom: 60px;
  padding: 20px;
  transition: transform 0.5s ease, opacity 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-content {
  max-width: 900px;
}

.section-home {
  text-align: left;
}

.welcome p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.game-list {
  list-style: none;
  padding: 0;
}

.game-item {
  background: rgba(50, 50, 50, 0.1);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 1.1rem;
  transition: background 0.3s ease;
}

.game-item:hover {
  background: rgba(50, 50, 50, 0.2);
}

.creator {
  margin-top: 40px;
  font-size: 1.2rem;
}

/* Houses sections */
.section-gryffindor,
.section-slytherin,
.section-hufflepuff,
.section-ravenclaw {
  background-color: #7F0909;
  color: white;
  text-align: center;
  padding: 40px 20px;
}

.section-slytherin {
  background-color: #1A472A;
}

.section-hufflepuff {
  background-color: #FFF4B1;
  color: #000;
}

.section-ravenclaw {
  background-color: #0E1A40;
}

.house-image {
  max-width: 150px;
  margin-top: 20px;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.house-content {
  margin-bottom: 20px;
}

.section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.section p {
  font-size: 1.3rem;
  line-height: 1.6;
}

/* Glassmorphism effect on tables */
.glass-table {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.glass-table:hover {
  transform: scale(1.05);
}

.section-gryffindor .glass-table:hover {
  box-shadow: 0 0 15px rgba(255, 69, 0, 0.8);
}

.section-slytherin .glass-table:hover {
  box-shadow: 0 0 15px rgba(0, 255, 127, 0.8);
}

.section-hufflepuff .glass-table:hover {
  box-shadow: 0 0 15px rgba(255, 255, 0, 0.8);
}

.section-ravenclaw .glass-table:hover {
  box-shadow: 0 0 15px rgba(70, 130, 180, 0.8);
}

/* Responsiveness */
@media (max-width: 768px) {
  .home-container {
    padding: 10px;
  }

  .section {
    width: 100%;
  }

  .house-image {
    max-width: 100px;
  }
}
