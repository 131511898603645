/* Geral */
body {
    font-family: 'Arial', sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
    margin: 0;
    padding: 0;
  }
  
  /* Estilo da página de notícia detalhada */
  .noticia-detalhada-page {
    max-width: 950px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .noticia-detalhada-content {
    padding: 20px;
  }
  
  /* Título da notícia */
  .noticia-detalhada-content h1 {
    font-size: 2.5rem;
    margin-bottom: 15px;
    color: var(--text-color);
    line-height: 1.2;
  }
  
  /* Data da notícia */
  .noticia-detalhada-date {
    font-size: 0.9rem;
    color: var(--secondary-color);
    margin-bottom: 25px;
  }
  
  /* Imagem da notícia */
  .noticia-detalhada-image {
    width: 100%;
    height: auto;
    margin-bottom: 25px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.1s ease;
    transform-style: preserve-3d;
    perspective: 1000px;
  }
  
  
  /* .noticia-detalhada-image:hover {
    transform: perspective(1000px) rotateY(15deg) rotateX(10deg) scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  } */
  
  
  /* Corpo da notícia */
  .noticia-detalhada-body {
    font-size: 1.1rem;
    line-height: 1.6;
    color: var(--text-color);
    margin-bottom: 30px;
  }
  
  .noticia-detalhada-body p {
    margin-bottom: 15px;
  }
  
  /* Links dentro do corpo da notícia */
  .noticia-detalhada-body a {
    color: var(--accent-color);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .noticia-detalhada-body a:hover {
    color: #FF6347; /* Tom mais claro de Fire Opal */
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .noticia-detalhada-page {
      padding: 10px;
    }
  
    .noticia-detalhada-content {
      padding: 10px;
    }
  
    .noticia-detalhada-content h1 {
      font-size: 2rem;
    }
  }
  