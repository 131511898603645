:root {
  --background-color: #1C1C1C; /* Charcoal */
  --primary-color: #3A3D98; /* Midnight Blue */
  --secondary-color: #5C5C6D; /* Slate Gray */
  --accent-color: #FF4500; /* Fire Opal */
  --text-color: #E0E0E0; /* Light Gray */
}

  body {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .header {
    background-color: var(--primary-color);
  }
  
  .button {
    background-color: var(--accent-color);
    color: var(--text-color);
  }
  
  .footer {
    background-color: var(--secondary-color);
  }

  .header {
    background-color: transparent;
  }

  .title{
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
  }
  