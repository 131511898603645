.forca-game {
    text-align: center;
    margin-top: 20px;
  }
  
  .forca-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .forca-image {
    width: 300px;
    margin-bottom: 20px;
  }
  
  .word-container {
    font-size: 24px;
    letter-spacing: 10px;
    margin-bottom: 20px;
  }
  
  .letter {
    font-size: 24px;
  }
  
  .keyboard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .keyboard-row {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .spacer-half {
    flex: 0.5;
  }
  
  .key {
    width: 50px;
    height: 50px;
    margin: 5px;
    border: none;
    border-radius: 10px;
    background-color: #1c1c1c;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 
      inset 5px 5px 10px rgba(0, 0, 0, 0.7), 
      inset -5px -5px 10px rgba(255, 255, 255, 0.1), 
      5px 5px 10px rgba(0, 0, 0, 0.5), 
      -5px -5px 10px rgba(255, 255, 255, 0.1);
  }
  
  .key:hover {
    background-color: #1c1c1c;
    box-shadow: 
      inset 5px 5px 15px rgba(0, 0, 0, 0.7),
      inset -5px -5px 15px rgba(255, 255, 255, 0.15),
      7px 7px 15px rgba(0, 0, 0, 0.5),
      -7px -7px 15px rgba(255, 255, 255, 0.1);
  }
  
  .key:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  
  .key.correct {
    background-color: #28a745; /* Verde para letras corretas */
    color: #ffffff;
  }
  
  .key.incorrect {
    background-color: #dc3545; /* Vermelho para letras incorretas */
    color: #ffffff;
  }
  
  .game-over {
    margin-top: 20px;
    font-size: 20px;
  }
  
  .restart-button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #1c1c1c;
    color: white;
    border: 2px solid #444;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .restart-button:hover {
    background-color: #333;
  }
  