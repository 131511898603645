.quiz-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.quiz-card {
    background: #1c1c1c;
    border-radius: 15px;
    box-shadow: 8px 8px 15px #0e0e0e, -8px -8px 15px #2a2a2a;
    width: 350px; /* Largura padrão para todos os cards */
    height: 320px; /* Altura padrão para todos os cards */
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.quiz-card:hover {
    transform: scale(1.05);
    box-shadow: 8px 8px 20px #0f0f0f, -8px -8px 20px #2b2b2b;
}

.quiz-card img {
    width: 100%;
    height: 80%; /* Proporção fixa para a imagem dentro do card */
    border-radius: 15px 15px 0 0;
    object-fit: cover; /* Ajusta a imagem para cobrir toda a área */
}

.quiz-title {
    padding: 15px;
    font-size: 16px; /* Tamanho da fonte ajustado para caber dentro do card */
    color: #d1d1d1;
    text-align: center;
    height:10%; /* Proporção fixa para a legenda dentro do card */
    display: flex;
    align-items: center;
    justify-content: center;
}
