.tabela-termo {
    display: grid;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
  }
  
  .term-row {
    display: flex;
    justify-content: center;
  }
  
  .term-cell {
    border-radius: 15px;
    width: 60px;
    height: 60px;
    border: 2px solid #ddd;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s;
  }
  
  .term-cell.correct {
    background-color: #6aaa64;
    border-color: #6aaa64;
    color: white;
  }
  
  .term-cell.present {
    background-color: #c9b458;
    border-color: #c9b458;
    color: white;
  }
  
  .term-cell.absent {
    background-color: #787c7e;
    border-color: #787c7e;
    color: white;
  }

  .tabela-termo.locked {
    opacity: 0.5; /* Deixa a tabela bloqueada um pouco mais transparente */
    pointer-events: none; /* Desabilita interações */
  }
  