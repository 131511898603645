.tabuleiro-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Espaço entre as tabelas */
    flex-wrap: wrap; /* Permite que as tabelas quebrem a linha se não couberem na mesma */
    margin-top: 20px;
}

/* Cada tabela mantém sua estrutura de 5 linhas em 1 coluna */
.tabela-termo {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
}

/* Ajuste do tamanho das células para caberem todas as tabelas */
.term-cell {
    border-radius: 15px;
    width: 50px; /* Tamanho padrão das células */
    height: 50px; /* Tamanho padrão das células */
    border: 2px solid #ddd;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s;
}

/* Ajustes para o modo Quinteto */
.quinteto .tabela-termo .term-cell {
    width: 40px; /* Tamanho menor para caber todas as tabelas */
    height: 40px; /* Tamanho menor para caber todas as tabelas */
    font-size: 16px; /* Reduzindo o tamanho da fonte */
}

/* Ajustes para o modo Quarteto */
.quarteto .tabela-termo .term-cell {
    width: 45px; /* Tamanho menor para o modo quarteto */
    height: 45px; /* Tamanho menor para o modo quarteto */
    font-size: 17px; /* Reduzindo o tamanho da fonte */
}

/* Estilos para as células */
.term-cell.correct {
    background-color: #6aaa64;
    border-color: #6aaa64;
    color: white;
}

.term-cell.present {
    background-color: #c9b458;
    border-color: #c9b458;
    color: white;
}

.term-cell.absent {
    background-color: #787c7e;
    border-color: #787c7e;
    color: white;
}



.mode-selector {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.mode-selector button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    background-color: #6aaa64;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.mode-selector button:hover {
    background-color: #5a9a54;
}

.title {
    text-align: center;
    margin-top: 20px;
    font-size: 24px;
    color: #ffffff;
}
