.modern-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  background-color: rgba(50, 50, 50, 0.4);
  color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  margin: 30px auto;
  width: 70vh;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, width 0.3s ease;
}

.modern-header:hover {
  width: 65vh;
  background-color: rgba(50, 50, 50, 0.8);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.nav-links {
  display: flex;
  gap: 30px;
  width: 100%;
  justify-content: center;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.nav-links a:hover {
  color: var(--accent-color);
  transform: scale(1.1);
}

.nav-links .active {
  color: var(--accent-color);
  font-weight: bold;
}

/* Estilos para dispositivos móveis */
.menu-icon {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .modern-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 20px;
    width: 50%;
  }

  .modern-header:hover {
    width: 50%;
    background-color: rgba(50, 50, 50, 0.8);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .nav-links a {
    padding: 10px 0;
    text-align: left;
    width: 100%;
  }

  .menu-icon {
    display: block;
  }

  .modern-header.open .nav-links {
    display: flex;
  }
}
